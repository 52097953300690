* {
  box-sizing: border-box;
}
.light {
  @import "./light-theme.scss";
  background-color: #f4f4f8;
  .page-content {
    min-height: 100vh;
  }
  .add-card {
    background-color: $add-cart-white-theme-color;
    color: $black;
  }
  .add-card:hover {
    border: 2px solid $black;
  }
  .add-card h6 {
    color: $black;
  }

  label {
    color: #495057;
  }

  .ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .card-layout {
    background-color: #f2f2f5;
  }

  .filter-chip-bg {
    background-color: #ffffff;
    border: 1px solid #556ee6;
  }
  .white-bg {
    background-color: #ffffff;
  }

  .seemore-text {
    color: black;
    margin-left: 5px;
  }
  .app-search {
    .form-control {
      background-color: white !important;
      color: black !important;
      border: 1px solid #ced4da;
    }
    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba(black, 0.5) !important;
    }
  }
  .card-header {
    background-color: #eff2f7;
    box-shadow: 1px 5px 13px 1px #ced4da;
    border: 0px;
    border-radius: 0px !important;
  }
  .block-header {
    color: black !important;
  }
  .number-circle {
    color: black !important;
  }
  .card-title {
    color: black !important;
  }
  .card-title h4 {
    color: black !important;
    margin-top: 10px;
  }
  .select2-selection__single-value {
    color: black !important;
  }
  .select2-selection__control {
    color: black !important;
  }
  .select2-selection__menu {
    color: black !important;
  }
  h6,
  p,
  .black-color,
  .card-subtitle {
    color: black;
  }
  .white-span {
    color: white;
  }
  td,
  td > div > p {
    color: #62687a !important;
  }
  .custom-control {
    font-size: 14px;
  }
  .modal-title {
    margin-top: 8px;
  }
  .extra-bin {
    padding: 5px 10px;
    border-radius: 14px;
    background-color: #eff2f7;
  }
  .account-pages {
    min-height: 100vh !important;
  }
  .apexcharts-yaxis text,
  .apexcharts-xaxis text {
    fill: black;
  }
  .apexcharts-legend-text {
    color: black;
  }
  .inventory-color {
    color: black;
  }
  .formulas-table-bg {
    background-color: white;
  }
  .formulas-equation-bg {
    background-color: white;
    border: 1px solid #CED4DA;
    border-radius: 5px;
  }

  .strategy-card-header{
    background-color: #F8F9FA;
    padding: 10px 16px;
  }

  .card-text {
    color: black;
  }
  .card-text-2 {
    color: #757575;
  }
  .registration-form {
    height: 100vh;
    overflow: auto;
  }
  .login-page-bg {
    clip-path: circle(50% at 36% 6%);
    background: #dacff1;
  }
  .filters-background {
    background-color: white;
  }
  .filter-accordian-background{
    background-color: #eff2f7;
  }

  .code-block{
    background-color: #fff;
  }

  .badge-background {
    background-color: #556ee62e;
  }

  .headings-text {
    color: #495057;
  }

  .card-table {
    background-color: #f4f4f8;
  }
}

.dark {
  @import "./dark-theme";
  background-color: #222737;
  .page-content {
    min-height: 100vh;
  }
  .footer {
    background-color: #262b3c;
  }
  .extra-bin {
    padding: 5px 10px;
    border-radius: 14px;
    background-color: #2e3548;
  }

  .filters-background{
    background-color: #2A3042;
  }
  .filter-accordian-background {
    background-color: #939CB91A;
  }

  .filter-chip-bg {
    background-color: #556EE626;
  }

  .card-layout{
    background-color: #22273780;
  }

  .inventory-color {
    color: white;
  }
  .formulas-table-bg {
    background-color: #2A3042;
  }
  .formulas-equation-bg {
    background-color: #2E3548;
    border: 1px solid #32394E;
    border-radius: 5px;
  }
  .card-text {
    color: #a6b0cf;
  }
  .card-text-2 {
    color: #757575;
  }

  .strategy-card-header{
    background-color: #373F53;
    padding: 10px 16px;
  }

  .ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .code-block{
    background-color: #2e3548;
  }

  .badge-background {
    background-color: #2E3A59;
  }

  .headings-text {
    color: #FFFFFF;
  }

  .card-table {
    background-color: #222737;
  }
}
.search-customize {
  .form-control {
    padding: 9px 91px 10px 15px;
    border-radius: 30px;
  }
}

.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  grid-column-gap: 16px;
}
.postiton-relative {
  position: relative;
}
.selection-box {
  display: inline;
  .search-icon {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .log-inputs {
    padding: 18px 36px;
    border-top-left-radius: 4px;
    border-radius: 0;
    border-bottom-left-radius: 4px;
  }
  .select2-container .select2-selection__control {
    border: none;
    border-top-right-radius: 4px;
    border-radius: 0;
    border-bottom-right-radius: 4px;
  }
  .outline-alert {
    background: linear-gradient(90deg, rgba(13, 60, 254, 0.135) 0%, rgba(34, 39, 55, 0) 98.18%);
    border: 1px solid #7ba5f8;
    color: #7ba5f8;
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
    margin-bottom: 24px;
    i {
      font-size: 16px;
      &.bx-x {
        color: #f46a6a;
        cursor: pointer;
      }
    }
  }
}
.dropzone-previews {
  background: #2e3548;
  border: 1px solid #363a48;
  border-radius: 5px;
}
.agency-badge {
  border: 1px solid #fbc02d;
  color: #fbc02d;
  border-radius: 6px;
  padding: 6px 12px;
}

.hide-input {
  background: none;
  border: none;
  width: 60%;
}
.gap-1 {
  grid-column-gap: 4px;
}

.flatpickr-day.today {
  border-color: white;
}

.react-datepicker__day--today {
  font-weight: 400;
}

.approve-box{
  div{
    border-right: 1px solid #E3E6EF;
    padding: 0 32px;
    &:last-child{
      border: none;
    }
  }
}

.text-success-emphasis {
  color: #25C35C
}

.text-warning-emphasis {
  color: #FD8A25
}
