.text-overflow-wrap {
  white-space: nowrap;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-pill {
  border-radius: 25px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.plan-border {
  border-color: #556ee6 !important;
}
.cursor-auto {
  cursor: auto !important;
}

.order-note-text {
  overflow: scroll;
  text-overflow: ellipsis;
  max-height: 76px;
}

.carousel-btns {
  width: 10% !important;
}

.carousel-btns-plans {
  width: 5% !important;
}

.order-note-divider {
  border: 0.5px solid #939cb959;
}
