// New Search Filters

.dark {
  .inputs-group {
    border-radius: 50px;
    background-color: #2e3548;
    .select2-selection__control {
      padding-left: 12px !important;
      background-color: #2e3548 !important;
      border-color: #2e3548 !important;
      border-radius: 50px;
      .select2-selection__value-container {
        min-width: 60px !important;
      }
    }
    .vertical-line2 {
      border-right: 1px solid #c4cce360 !important;
      height: 2rem !important;
      text-align: center !important;
      margin-top: 1% !important;
      margin: 0 8px;
    }
    input {
      padding-left: 10px !important;
      background-color: #2e3548 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      &.search-input {
        min-width: 280px !important;
      }

      &:focus {
        z-index: 0 !important;
      }
    }
  }
}

.light {
  .inputs-group {
    border-radius: 50px;
    background-color: #fff;
    .app-search {
      .form-group {
        border: none !important;
      }
    }
    .select2-selection__control {
      padding-left: 12px !important;
      background-color: #fff !important;
      border-color: #fff !important;
      border-radius: 50px;
      .select2-selection__value-container {
        max-width: 60px !important;
      }
    }
    .vertical-line2 {
      border-right: 1px solid #000 !important;
      height: 2rem !important;
      text-align: center !important;
      margin-top: 1% !important;
      margin: 0 8px;
    }
    input {
      padding-left: 10px !important;
      min-width: 280px !important;
      background-color: #2a3042;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: none !important;
      &.search-input {
        min-width: 280px !important;
      }
      &:focus {
        z-index: 0 !important;
      }
    }
  }
}

// New Search Filters

.dark {
  .inputs-group-inbounds {
    border-radius: 50px;
    background-color: #2e3548;
    .select2-selection__control {
      padding-left: 12px !important;
      background-color: #2e3548 !important;
      border-color: #2e3548 !important;
      border-radius: 50px;
      .select2-selection__value-container {
        min-width: 120px !important;
      }
    }
    .vertical-line2 {
      border-right: 1px solid #c4cce360 !important;
      height: 2rem !important;
      text-align: center !important;
      margin-top: 1% !important;
      margin: 0 8px;
    }
    input {
      padding-left: 10px !important;
      background-color: #2e3548 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;

      &.search-input {
        min-width: 280px !important;
      }

      &:focus {
        z-index: 0 !important;
      }
    }
  }
}

.light {
  .inputs-group-inbounds {
    border-radius: 50px;
    background-color: #fff;
    .app-search {
      .form-group {
        border: none !important;
      }
    }
    .select2-selection__control {
      padding-left: 12px !important;
      background-color: #fff !important;
      border-color: #fff !important;
      border-radius: 50px;
      .select2-selection__value-container {
        max-width: 120px !important;
      }
    }
    .vertical-line2 {
      border-right: 1px solid #000 !important;
      height: 2rem !important;
      text-align: center !important;
      margin-top: 1% !important;
      margin: 0 8px;
    }
    input {
      padding-left: 10px !important;
      min-width: 280px !important;
      background-color: #2a3042;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: none !important;
      &.search-input {
        min-width: 280px !important;
      }
      &:focus {
        z-index: 0 !important;
      }
    }
  }
}

.dark {
  .inbound-date-range {
    background-color: #2c3346;
    margin: 0px;
    padding: 0px;
    height: 36px;
    border-radius: 3px;
    input {
      border-color: transparent !important;
    }
  }
}

.light {
  .inbound-date-range {
    background-color: #fff;
    margin: 0px;
    padding: 0px;
    height: 36px;
    border-radius: 3px;
    input {
      border-color: transparent !important;
    }
  }
}
