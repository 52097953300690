@import "../../assets/scss/dark-theme.scss";

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
  display: inline-block;
  background-color: $gray-300;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.radio-toolbar input[type="radio"]:checked + label {
  background-color: $primary;
  border: none;
  color: $white;
}

#radio-title {
  margin-bottom: 5px;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 12px;
  height: 12px;
  border: 0.15em solid currentColor;
  border-radius: 50%;
}

input[type="radio"]:checked {
  border-color: #556ee6;
  border-width: 4px;
}
