@font-face {
  font-family: "icomoon";
  src: url("./icomoon/fonts/icomoon.eot?7f003r");
  src: url("./icomoon/fonts/icomoon.eot?7f003r#iefix") format("embedded-opentype"),
    url("./icomoon/fonts/icomoon.ttf?7f003r") format("truetype"),
    url("./icomoon/fonts/icomoon.woff?7f003r") format("woff"),
    url("./icomoon/fonts/icomoon.svg?7f003r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-inventory:before {
  content: "\e905";
  color: #556ee6;
}

.icon-refresh:before {
  content: "\e904";
  color: #556ee6;
}

.icon-topRight .path1:before {
  content: "\e900";
  color: rgb(166, 176, 207);
}

.icon-topRight .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(85, 110, 230);
}

.icon-bottomleft .path1:before {
  content: "\e902";
  color: rgb(85, 110, 230);
}

.icon-bottomleft .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(166, 176, 207);
}

.icon-sort-amount-desc:before {
  content: "\ea4d";
}

.vertical-line {
  border-right: 1px solid $vertical-line-color;
  height: 38px;
  margin-top: 2vh;
  margin-left: auto;
}

.separation-line-listing {
  border-right: 1px solid $vertical-line-color;
  height: 28px;
}

.wrap-text-to-paragraph {
  white-space: normal !important;
}

.toc-heading-cursor {
  cursor: pointer;
}

.fixed-position {
  position: fixed;
}

.box-shadow {
  border: 1.5px solid $border-color;
  padding: 20px;
}

.advance-option-border {
  border: 1px solid $advance-option-border-color;
}

.separator-process-order {
  border-right: 1px solid $vertical-line-color;
  height: auto;
}

.process-modal-bg-color {
  background-color: $process-model-background;
}

.carousel.slide {
  width: 100%;
}

.progress-plan {
  width: 50vw;
  height: 3vh;
  border-radius: 1rem;
}

.progress-bar-animated {
  overflow: initial;
  color: $progress-bar-text-color;
}

#used-credits .apexcharts-tooltip-text-value {
  color: black;
}

@mixin scrolling-div($max-height) {
  max-height: $max-height;
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-div-450 {
  @include scrolling-div(calc(100vh - 450px));
}

.block-header {
  color: #c3cbe4;
  font-size: 1.01562rem;
}

.number-circle {
  border-radius: 50%;
  padding: 1px;
  font-size: 13px;
  background: #c3cbe4;
  color: $light;
  text-align: center;
  width: 20px;
  height: 20px;
}

.vl {
  border-left: 2px solid #c3cbe4;
  height: 40px;
}

.email-view {
  background-color: white !important;
}

.email-view h3,
.email-view p {
  color: black;
}

.form-group {
  .invalid-feedback {
    display: inline;
  }
}

.btn-least-selling {
  min-width: 230px;
  margin: 14px 10px 0 10px;
}

.radio-select {
  .form-group {
    .form-control {
      background-color: transparent;
    }
  }
}

.miw-160 {
  min-width: 160px;
}

.miw-60p {
  min-width: 60%;
  max-width: 60%;
}

.miw-search-input {
  min-width: 300px;
  max-width: 300px;
}

.font-size-normal {
  font-style: normal !important;
}

.hrMargin {
  margin: 5px 0;
  border-top: 1px solid #454b5c;
}

.flex-direction-column {
  flex-direction: column;
}

.grid-column-gap-30 {
  grid-column-gap: 30px;
}

.grid-column-gap-10 {
  grid-column-gap: 10px;
}

.w-200 {
  width: 200px !important;
}

.w-230 {
  width: 230px !important;
}

.add-client {
  .container,
  .card-body {
    padding: 0 !important;
  }
}

.mt1px {
  margin-top: 1px;
}

.mw-300 {
  max-width: 300;
  white-space: inherit !important;
}

input.PhoneInputInput {
  @extend .form-control;
}

.row-gap-10 {
  row-gap: 10px;
}

.payment-card {
  background: #32394e;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  min-height: 238px;

  &.red {
    border: 3px solid red;
  }
}

.payment-card-light {
  background: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  min-height: 238px;

  &.red {
    border: 3px solid red;
  }
}

.payment-card-light h6 {
  color: #7e8bb0;
  font-size: 12px;
}

.payment-card-light span {
  color: #495057;
  font-size: 13px;
}

.payment-card h6 {
  color: #c3cbe4;
  font-size: 12px;
}

.payment-card span {
  color: #7e8bb0;
  font-size: 13px;
}

.bg-color-remove {
  background: rgba(85, 110, 230, 0.12);
}

.bg-color-remove h6 {
  color: #556ee6;
}

.add-card {
  background: #32394e;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 238px;
}

.add-card:hover {
  border: 2px solid #7480a3;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
}

.add-card h6 {
  color: #7e8bb0;
}

.fs-30 {
  font-size: 30px;
}

.card-type {
  height: 10px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 13px;
}

.grid-column-gap-5 {
  grid-column-gap: 5px;
}

.icon-portion {
  i {
    &#warning-0 {
      color: #c00;
    }
  }
}

.flex-direction-row {
  flex-direction: row;
}

.grid-colum-gap-10 {
  grid-column-gap: 10px;
}

.footertext {
  padding-left: 10px;
  padding-right: 10px;
}

.footertext + .footertext {
  border-left: 2px solid rgba(195, 203, 228, 0.5);
}

.mb-0 {
  margin-bottom: 0;
}

.fs-16 {
  font-size: 16px;
}

.email-wrap {
  white-space: pre-wrap;
  max-width: 237px;
}

.grid-column-gap-15 {
  grid-column-gap: 15px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.grid-column-gap-20 {
  grid-column-gap: 20px;
}

.grid-column-gap-20 {
  grid-column-gap: 20px;
}

.signup-title {
  justify-content: space-between;
  border-right: 1px solid #7a8197;

  &:last-child {
    padding-right: 10px;
  }

  @media (max-width: 992px) {
    border-right: none;
  }
}

.vertical-line-2 {
  border-right: 1px solid #7b8197;
  height: 42px;
  margin-top: 0;

  @media (max-width: 992px) {
    border-right: none;
  }
}

.tent-pagination {
  .flex-column {
    flex-direction: row !important;
    grid-column-gap: 30px;
    align-items: center;
  }
}

.mx-h-500 {
  max-height: 510px;
  min-height: 500px;
  overflow-y: auto;
}

.mx-h-500::-webkit-scrollbar-corner {
  background: #2a3142;
}

.tentent-h5 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  display: inline;
  cursor: pointer;
}

.select-accounts {
  .select2-container {
    width: 100%;
    margin-bottom: 5px;
  }

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
}

@media (max-width: 475px) {
  .width-100-sm {
    div {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.pagination-table {
  .pagination {
    .page-item {
      button {
        @media (max-width: 375px) {
          padding: 8px 10px;
        }
      }
    }
  }
}

.select2-container {
  .select2-selection__control {
    .select2-selection__value-container {
      min-width: 30px;
    }
  }
}

.grid-colum-gap-5 {
  grid-column-gap: 5px;
}

table {
  thead {
    tr {
      position: sticky;
      width: 100%;
      top: -2px;
      background-color: #32384b;
    }
  }
}

.notifaction_email {
  display: flex;
  grid-column-gap: 5px;
  align-items: center;

  span {
    color: #a7b1ce;
    font-size: 12px;
  }
}

.notification_email_input {
  .form-control {
    border-radius: 50px;
  }
}

.dark {
  .bg-color-ebony {
    background-color: #222737;
  }
}

.w-500 {
  width: 500px;
}

.border-radius-50 {
  border-radius: 50px;
}

.dark {
  .modal_route {
    &:hover {
      color: #fff;
    }

    cursor: pointer;
    padding: 0.4rem 1.5rem 0.4rem 3.5rem;
    color: #79829c;
  }

  .seemore-text {
    color: #ffff;
    margin-left: 5px;
  }
}

//resources scss
.dark {
  .resources {
    h5 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .community-box {
      background-color: #2a3142;
      box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
      img {
        max-width: 100%;
      }

      .content-box {
        padding: 25px;

        span {
          color: #fff;
          font-size: 0.93rem;
        }

        p {
          font-size: 0.8125rem;
          color: #c4cce3;
          min-height: 85px;
        }

        a {
          font-size: 0.8125rem;
          color: #556ee6;
        }
      }
    }

    .services_tool {
      background-color: #2a3142;
      box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
      padding: 25px;
      margin-top: 25px;

      .mb-20 {
        margin-bottom: 20px;

        h6 {
          font-size: 1.375rem;
          font-weight: 600;
        }
      }

      .mb-40 {
        margin-bottom: 40px;
      }

      .image-box {
        background-color: #222737;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }

      h6 {
        font-weight: 400;
      }

      p {
        font-size: 0.8125rem;
        color: #c4cce3;
      }

      .services_description {
        .arrow_icon {
          color: #556ee6;
          cursor: pointer;
        }

        span {
          color: #fff;
          font-size: 1rem;
        }

        p {
          font-size: 0.75rem;
          color: #c4cce3;
          margin-bottom: 0;
          min-height: 70px;
        }

        a {
          font-size: 0.8125rem;
          color: #556ee6;
        }
      }
    }
  }
}

//resources scss
.light {
  .resources {
    h5 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .community-box {
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);

      img {
        max-width: 100%;
      }

      .content-box {
        padding: 25px;

        span {
          color: #000;
          font-size: 0.93rem;
        }

        p {
          font-size: 0.8125rem;
          color: #74788d;
          min-height: 85px;
        }

        a {
          font-size: 0.8125rem;
          color: #556ee6;
        }
      }
    }

    .services_tool {
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
      padding: 25px;
      margin-top: 25px;

      .mb-20 {
        margin-bottom: 20px;

        h6 {
          font-size: 1.375rem;
          font-weight: 600;
        }
      }

      .mb-40 {
        margin-bottom: 40px;
      }

      .image-box {
        background-color: #eeeef3;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }

      h6 {
        font-weight: 400;
      }

      p {
        font-size: 0.8125rem;
        color: #74788d;
      }

      .services_description {
        .arrow_icon {
          color: #556ee6;
          cursor: pointer;
        }

        span {
          color: #000;
          font-size: 1rem;
        }

        p {
          font-size: 0.75rem;
          color: #74788d;
          margin-bottom: 0;
          min-height: 70px;
        }

        a {
          font-size: 0.8125rem;
          color: #556ee6;
        }
      }
    }
  }
}

.light {
  .modal_route {
    &:hover {
      color: #545a6d;
    }

    cursor: pointer;
    padding: 0.4rem 1.5rem 0.4rem 3.5rem;
    color: #545a6d;
  }
}

.dark {
  .bm-payment-modal {
    background: #32394e;
    .bm-header-subtitle {
      color: #fff !important;
    }
    .bm-row-input {
      background-color: #fff !important;
    }
  }
}

.bm-payment {
  position: inherit;
  top: 0;
  left: 0;
  padding: 0;
  &.blocker {
    position: fixed;
  }
  .bm-banner {
    border-radius: 3px;
    padding: 5px 12px;
    margin-bottom: 10px;
    font-size: 12px;
    width: 100%;
    min-width: 100%;
    position: relative;
    top: 0;
    z-index: 1;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: #e23c3f;
    color: #fff;
    .bm-banner-message {
      margin-bottom: 0;
    }
  }
}

.dark .yellow-outline {
  border: 1px solid #fbc02d;
  color: #fbc02d !important;
  min-width: 110px;
  padding: 7px;
  border-radius: 5px;
}

.light .blue-outline {
  border: 1px solid #556ee6;
  color: #556ee6 !important;
  min-width: 110px;
  padding: 7px;
  border-radius: 5px;
}

/* alert */
.alert-main-div {
  border-radius: 4px !important;
  padding: 8px 10px !important;
  margin: 1px 0 0 0 !important;
}

.alert-main-div.primary {
  border: 1px solid $primary !important;
  background-image: linear-gradient(to right, rgba($primary, 0.1), transparent) !important;
  color: $primary !important;
}

.alert-main-div.success {
  border: 0.7px solid $success !important;
  background-image: linear-gradient(to right, rgba($success, 0.1), transparent) !important;
  color: $success !important;
}

.alert-main-div.warning {
  border: 0.7px solid $warning !important;
  background-image: linear-gradient(to right, rgba($warning, 0.1), transparent) !important;
  color: $warning !important;
}

.alert-main-div.danger {
  border: 0.7px solid $danger !important;
  background-image: linear-gradient(to right, rgba($danger, 0.1), transparent) !important;
  color: $danger !important;
}

.top-search-arrow-icon {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.danger {
  color: #ff0000;
}
.color-danger{
  color: #F46A6A;
}

.warning {
  color: #ffe500;
}

.dark {
  .color-white {
    color: #fff;
  }
}

.color-primary {
  color: #34c38f;
}

.color-purple {
  color: #556ee6 !important;
}

.editableInput {
  min-width: 130px;

  input {
    max-width: 80px;

    &:focus {
      border: 1px solid #556ee6 !important;
    }
  }
}

.weightInput {
  width: 120px;

  input {
    max-width: 80px;
  }
}

.editableInputTemp {
  width: 100% !important;

  .form-group {
    width: 60%;

    &:focus {
      border: 1px solid #556ee6 !important;
    }
  }
}
.template-edit-td {
  width: 500px;
}

//collapse table
.collapse-hide {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.collapse-show {
  opacity: 1;
  display: table-row;

  &.table-tr {
    background-color: $body-bg;
  }

  td {
    border-top: none;
  }
}

.light {
  .collapse-show {
    opacity: 1;
    display: table-row;

    &.table-tr {
      background-color: $body-bg;
    }

    td {
      border-top: none;
    }
  }
}

.selected-tr {
  background-color: $body-bg;
}

.default-supplier.table-tr,
.edit-supplier-bg {
  background-color: $card-bg-light;
}

.add-border {
  border-left: 5px solid #f33c3c;
}

.border-primary {
  border-left: 5px solid #556ee6;
}

.border-orange {
  border-left: 5px solid #f59e42;
}

.mw-10 {
  max-width: 10px;
  min-height: 89px;
}

.table-tr td {
  &:nth-child(1) {
    width: 5px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.variation-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 6vw;
}

.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}

.badge-warning {
  background: rgba(255, 138, 0, 0.15);
  color: #ff8a00;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-soft-orange {
  background: rgba(255, 138, 0, 0.15);
  color: #ff8a00;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-customization {
  background: rgba(255, 65, 65, 0.15);
  color: #eb5e5e;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-success {
  background: rgba(52, 195, 143, 0.18);
  color: #34c38f;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-soft-warning {
  background: rgba(255, 229, 0, 0.15);
  color: #ffe500;
  padding: 5px 20px;
  min-width: 110px;
}
.badge-soft-purple {
  background: rgba(157, 96, 251, 0.2);
  color: #9d60fb;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-soft-custom {
  background: rgba(186, 186, 186, 0.15);
  color: #a4a3a3;
  padding: 5px 20px;
  min-width: 110px;
}

.badge-soft-success {
  padding: 5px 17px;
}

.badge-soft-danger {
  padding: 5px 17px;
}

.min-height-60 {
  min-height: 60px;
}

.grid-row-gap-11 {
  grid-row-gap: 11px;
}

.grid-row-gap-9 {
  grid-row-gap: 9px;
}

.icons-sizes {
  i {
    width: 24px;
    height: 24px;
  }
}

.supplier-table-height {
  min-height: 100px;
}

.change-checkbox {
  .custom-control-label {
    &:before {
      border: 1px solid #545b72;
      background-color: transparent;
    }
  }
}

.dark {
  .editable-input {
    max-width: 60%;
    background: #2e3548;
    border: 1px solid #434a5f;
    height: 37px;
    padding: 9px;
    border-radius: 5px;
  }
}

.light {
  .editable-input {
    max-width: 60%;
    background: transparent;
    border: 1px solid #434a5f;
    height: 37px;
    padding: 9px;
    border-radius: 5px;
  }
}

.vw-checkbox {
  width: 1vw;
}

.selected-tr-bg-clr {
  background-color: $selected-table-row;
}

.px-20 {
  padding: 8px 30px;
}

.bm-cancel {
  z-index: 1020 !important;
  position: fixed !important;
  top: 0 !important;
  .bm-background {
    background-color: #00000070 !important;
  }
  .bm-cancel-modal {
    padding: 10px 40px;
    .bm-modal-header {
      .bm-header-icon {
        font-size: 70px;
      }
      .bm-header-title {
        font-size: 16px;
      }
      .bm-header-subtitle {
        color: #7171a6;
        font-size: 14px;
      }
    }
    .bm-modal-error {
      padding: 12px;
      font-size: 14px;
    }
    .bm-modal-form {
      font-size: 12px;
      .bm-form-row {
        .bm-row-cell {
          .bm-row-textarea {
            min-height: 80px;
          }
        }
      }
    }
    .bm-modal-action {
      margin-top: 20px;
      font-size: 14px;
      .bm-action-submit {
        height: 30px;
        font-size: 12px;
        background-color: #556ee6 !important;
      }
      .bm-nevermind {
        font: size 12px;
        color: #556ee6 !important ;
      }
    }
  }
}

.hide-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.min-width-150 {
  min-width: 150px;
}

.averge-input {
  border: 1px solid #556ee6;
  border-radius: 3px;
  max-width: 150px;
  min-width: 150px;
  input {
    border-left: 1px solid #556ee6;
    &:focus {
      border-left: 1px solid #556ee6;
    }
  }
}

.db-card-50-50 {
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
}

.mh-493 {
  min-height: 493px;
  max-height: 493px;
  overflow: auto;
}

.order-row-h {
  height: 60px;
}

.dark .dashboard-navabr {
  grid-column-gap: 10px;
  li {
    a {
      font-size: 10px;
      padding: 6px 14px;
      white-space: nowrap;
      background: #32394e;
      border: 1px solid #32394e;
      border-radius: 4px;
      &.active {
        background-color: #222736 !important;
      }
    }
  }
}
.light .dashboard-navabr {
  grid-column-gap: 10px;
  li {
    a {
      font-size: 10px;
      padding: 6px 14px;
      white-space: nowrap;
      border-radius: 4px;
    }
  }
}

.datepick-heading-w {
  width: 555px;
}

.transparent-button {
  background-color: transparent !important;
  border: none;
  padding: 0;
  &:focus {
    border-color: none;
    box-shadow: none;
  }
  &:active {
    border-color: none;
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.dark {
  .card {
    &.pdf-graph {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 0 solid #f6f6f6;
      border-radius: 0.25rem;
      .card-title {
        color: #000;
      }
      .apexcharts-yaxis text,
      .apexcharts-xaxis text,
      .apexcharts-xaxis text {
        fill: #000;
      }
      .apexcharts-legend-text {
        color: #74788d !important;
      }
      .apexcharts-gridline {
        stroke: #f1f1f1 !important;
      }
    }
  }
}

.wh-card-media {
  margin-left: 20px;
}

.media-seperator {
  margin-left: 40px;
  margin-top: 10px;
  height: 30px;
  width: 1px;
  background: #424b61;
}

.group-btn-color {
  color: #c3cbe4;
}
.bm-cancel {
  position: fixed !important;
  top: 0 !important;
}
.upload-images {
  .upload-img {
    position: relative;
    position: relative;
    height: 147px;
    padding: 8px;
    border: 1px solid #9c9595;
    border-radius: 2px;
    margin-bottom: 10px;
    min-width: 223px;
    img {
      height: 135px;
      width: 206px;
    }
    i {
      display: none;
    }
    &:hover {
      i {
        position: absolute;
        right: -13px;
        display: block;
        top: -12px;
        cursor: pointer;
      }
    }
  }
  .drag-image {
    max-width: 228px;
    min-height: 164px;
    .dropzone {
      min-height: 149px;
      border: 2px dashed $gray-400;
      background: $card-bg;
      border-radius: 6px;

      .dz-message {
        font-size: 24px;
        width: 100%;
      }
    }
  }
}

// crate label genation
.dark .label-genertaion {
  background: #2a3042;
  .drawer-heading {
    padding: 27px 30px;
    h6 {
      font-size: 16px;
    }
    i {
      width: 24px;
      height: 24px;
      margin: -4px -3px;
    }
  }
  hr {
    margin: -11px 0;
  }
  .drawer-table {
    padding: 30px;
    padding-bottom: 0;
    .ship {
      padding: 5px 1px;
      .d-flex {
        grid-column-gap: 24px;
        align-items: center;
        margin-bottom: 16px;
        label {
          white-space: nowrap;
          margin-bottom: 0;
          min-width: 67px;
        }
        input {
          padding: 7px 15px;
        }
      }
    }
    .items {
      padding: 6px 0;
      h6 {
        font-size: 16px;
      }
      .items-table {
        thead {
          tr {
            th {
              padding: 11px 5px;
              text-align: left;
              &:first-child {
                width: 20px;
                padding-left: 19px;
              }
              &:nth-child(2) {
                width: 415px;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 0.5px solid rgba(147, 156, 185, 0.2);
            td {
              .custom-control {
                padding-left: 39px;
                margin-top: 0px;
              }
              .form-control:focus {
                border-color: #556ee6 !important;
              }
              .text-truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 362px;
              }
              padding: 10px 5px;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .info {
    padding: 42px 30px;
    padding-bottom: 9px;

    .size {
      display: flex;
      grid-column-gap: 15px;
      margin-bottom: 15px;
      label {
        min-width: 78px;
        margin-top: 7px;
      }
      .input-group-text {
        background: #2e3548;
      }
    }
  }
  .select2-container {
    width: 100%;
    align-items: baseline;
    margin-bottom: 7px;
    label {
      min-width: 94px;
    }
  }
  .est-time {
    padding: 26px 0;
    .delivery-days {
      span:nth-child(2) {
        padding-left: 18px;
        color: #34c38f;
      }
    }
    .rate {
      align-items: center;
      grid-column-gap: 11px;
      margin: -8px -5px;
      padding: 2px 0;
      span:nth-child(2) {
        font-size: 24px;
        color: #34c38f;
      }
    }
  }
  .drawer-footer {
    padding: 32px 23px;
    grid-column-gap: 11px;
    .btn-outline-primary {
      padding: 0px 29px;
    }
    .btn-primary {
      padding: 8px 18px;
    }
  }
}
.light .label-genertaion {
  background: #fff;
  .drawer-heading {
    padding: 27px 30px;
    h6 {
      font-size: 16px;
    }
    i {
      width: 24px;
      height: 24px;
      margin: -4px -3px;
    }
  }
  hr {
    margin: -11px 0;
  }
  .drawer-table {
    padding: 30px;
    padding-bottom: 0;
    .ship {
      padding: 5px 1px;
      .d-flex {
        grid-column-gap: 24px;
        align-items: center;
        margin-bottom: 16px;
        label {
          white-space: nowrap;
          margin-bottom: 0;
          min-width: 67px;
        }
        input {
          padding: 7px 15px;
        }
      }
    }
    .items {
      padding: 6px 0;
      h6 {
        font-size: 16px;
      }
      .items-table {
        thead {
          tr {
            th {
              background-color: #fff;
              padding: 11px 5px;
              text-align: left;
              &:first-child {
                width: 20px;
                padding-left: 19px;
              }
              &:nth-child(2) {
                width: 415px;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 0.5px solid rgba(147, 156, 185, 0.2);
            td {
              .custom-control {
                padding-left: 38px;
                margin-top: 0px;
              }
              .form-control:focus {
                border-color: #556ee6 !important;
              }
              .text-truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 362px;
              }
              padding: 10px 5px;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .info {
    padding: 42px 30px;
    padding-bottom: 9px;

    .size {
      display: flex;
      grid-column-gap: 15px;
      margin-bottom: 15px;
      label {
        min-width: 78px;
        margin-top: 7px;
      }
      .input-group-text {
        background: #fff;
      }
    }
  }
  .select2-container {
    width: 100%;
    align-items: baseline;
    margin-bottom: 7px;
    label {
      min-width: 94px;
    }
  }
  .est-time {
    padding: 26px 0;
    .delivery-days {
      span:nth-child(2) {
        padding-left: 18px;
        color: #34c38f;
      }
    }
    .rate {
      align-items: center;
      grid-column-gap: 11px;
      margin: -8px -5px;
      padding: 2px 0;
      span:nth-child(2) {
        font-size: 24px;
        color: #34c38f;
      }
    }
  }
  .drawer-footer {
    padding: 32px 23px;
    grid-column-gap: 11px;
    .btn-outline-primary {
      padding: 0px 29px;
    }
    .btn-primary {
      padding: 8px 18px;
    }
  }
}
.input-width {
  width: 20px;
}

.divider-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: #32384b;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin-top: 16px;

  .inner-text {
    border: 2px solid #32384b;
    border-radius: 50px;
    padding: 3px 15px;
  }

  &::before {
    position: relative;
    top: 50%;
    width: 50%;
    right: 8px;
    border-top: 2px solid #32384b;
    border-top-color: inherit;
    border-bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    content: "";
  }

  &::after {
    position: relative;
    top: 50%;
    width: 50%;
    left: 8px;
    border-top: 2px solid #32384b;
    border-top-color: inherit;
    border-bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    content: "";
  }
}

.border-2 {
  border-width: 2px !important;
}

.existing-wh-btn-width {
  width: 109px;
}

.SiteDetails.Secondary {
  span {
    display: none;
  }
}
.generate-label {
  &.disabled {
    filter: invert(79%) sepia(29%) saturate(230%) hue-rotate(188deg) brightness(85%) contrast(92%);
    opacity: 0.7;
    cursor: not-allowed;
    user-select: none;
  }
  filter: invert(41%) sepia(38%) saturate(2768%) hue-rotate(215deg) brightness(92%) contrast(95%);
}
.see-more-text {
  max-width: 400px;
  min-width: 350px;
  white-space: initial;
}
.custom-tooltip {
  .tooltip-example {
    position: absolute;
    right: 0;
    width: 240px;
    text-align: center;
    top: -64px;
    left: -194px;
    background-color: #000;
    color: #fff;
    padding: 9px 11px;
    border-radius: 4px;
    display: none;
  }
  &:hover {
    .tooltip-example {
      display: block;
    }
  }
}

.dark .checked-box {
  background-color: #222737;
  padding: 16px;
  border-radius: 50px;
  margin-bottom: 30px;
}
.add-spacing {
  margin-top: 70px;
  padding: 0 100px;
}
.or-seperation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #a7b1ce;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  border-top: 0;
  border-top-color: #a7b1ce;
  clear: both;
  width: 30%;
  margin-top: 7px;
  &::before {
    position: relative;
    top: 12%;
    width: 10%;
    right: 8px;
    border-top: 1px solid #a7b1ce;
    border-top-color: inherit;
    border-bottom: 0;
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
    content: "";
  }

  &::after {
    position: relative;
    top: 12%;
    width: 10%;
    left: 8px;
    border-top: 1px solid #a7b1ce;
    border-top-color: inherit;
    border-bottom: 0;
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
    content: "";
  }
}

.cross-icon {
  position: absolute;
  right: 14px;
  top: 14px;
}

.upload-file {
  background-color: #2e3548;
  border: 1px solid #363a48;
  border-radius: 5px;
  position: relative;
  padding: 8px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 114px;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}
.image-detail {
  font-size: 12px;
  .trash-icon {
    color: #f33c3c;
    right: 140px;
    top: 21px;
    cursor: pointer;
  }
  .details {
    span:nth-child(2),
    span:nth-child(3) {
      color: #c3cbe4;
      font-weight: 600;
    }
  }
}
.carousel {
  .follow-steps {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.73);
  }
  .carousel-control-prev,
  .carousel-control-next {
    background-color: #0000000f;
  }
}

.input-copy {
  .copy-icon {
    right: 14px;
    top: 33px;
    cursor: pointer;
  }
}

.clip-board-icon {
  right: -241px;
  top: 10px;
}

.see-more-text {
  max-width: 400px;
  min-width: 350px;
  white-space: initial;
}

.min-width-400 {
  min-width: 400px;
}

.font-20 {
  font-size: 20px;
}

.desk--widget.bottom-right {
  background-color: transparent;
}

.align-items-baseline {
  align-items: baseline;
}
.divider {
  background-color: #424b61;
  width: 1px;
  height: 20px;
  margin: 0 20px;
}

.po-input-100 {
  min-width: 100px;
  max-width: 100px;
}
.po-input-141 {
  min-width: 141px;
  max-width: 141px;
}

.po-input-150 {
  min-width: 150px;
  max-width: 150px;
}

.po-input-250 {
  min-width: 250px;
}
.min-height-55 {
  min-height: 55px;
}
.purchase-order {
  .outline-alert {
    background: linear-gradient(90deg, rgba(13, 60, 254, 0.135) 0%, rgba(34, 39, 55, 0) 98.18%);
    border: 1px solid #7ba5f8;
    color: #7ba5f8;
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
    margin-bottom: 24px;
    i {
      font-size: 16px;
      &.bx-x {
        color: #f46a6a;
        cursor: pointer;
      }
    }
  }
  .card {
    .collapse-card {
      background-color: $body-bg;

      td {
        color: $sidebar-bg;
      }

      tbody {
        background-color: $body-bg;
      }
    }
  }
  tbody {
    tr {
      &.no-shipment {
        background: rgba(244, 106, 106, 0.15);
        border-left: 2px solid #f46a6a;
      }
      &.missing-quanity {
        background: rgba(251, 192, 45, 0.15);
        border-left: 2px solid #fbc02d;
      }
    }
  }
  .collapse-hide {
    display: none;
  }
  .collapse-show {
    display: table-row;
    .card:first-child {
      border: 1px solid rgba(85, 110, 230, 0.24);
    }
  }
  .purchase-row {
    display: none;
  }
}

.light .purchase-order .card .collapse-card {
  table {
    thead {
      tr th {
        background-color: #ffff;
      }
    }
  }
}

.tr-w {
  min-width: 250px;
  max-width: 250px;
}
.dark {
  .popover-inner {
    background-color: $body-bg;
  }
}
.light {
  .popover-inner {
    background-color: #fff;
    color: #62687a !important;
  }
}

.w-80 {
  width: 220px;
}
.border-radius-none {
  border-radius: 0;
}
.border-radius-sm {
  border-radius: 8px;
}
.outbound-items {
  display: block;
  .purchase-order-widgets {
    .card-body {
      padding: 16px;
      .avatar-sm {
        width: 2.5rem;
        height: 2.5rem;
      }
      p {
        font-size: 12px;
      }
    }

    &:last-child {
      .media-seperator {
        display: none;
      }
    }
  }
  .media-seperator {
    margin-left: 8px;
  }
}
.calender-icon {
  position: absolute;
  right: 7px;
  top: 9px;
  cursor: pointer;
}
.overflow-add-modal {
  .text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .counter-circle {
    background: $body-bg;
    border: 1px solid $body-bg;
    border-radius: 50%;
    padding: 6px 8px;
    font-size: 12px;
    margin-left: 8px;
    min-width: 33px;
    text-align: center;
    cursor: pointer;
  }
}
.text-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.card-heading-seperator {
  margin-left: 17px;
  margin-top: 0;
  height: 20px;
  width: 1px;
  background: #424b61;
}
.warhouse-remove {
  top: -6px;
  right: -4px;
}
.item-details-slider {
  &.slide {
    width: 120px;
  }
  .carousel-inner {
    height: 103px;
  }
}
.marketplace {
  h5 {
    font-size: 13px;
    color: #c3cbe4;
  }
}
.bottom-space {
  margin-bottom: -4px;
}

.bin-count {
  background-color: #2f3648;
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 7px 0px 0px 0px;
}

.bs-popover-top > .arrow::after,
.bs-popover-top > .arrow::before,
.bs-popover-bottom > .arrow::after,
.bs-popover-bottom > .arrow::before {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}
.label-wrapper {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
  margin-bottom: 4px !important;
}

.text-right {
  text-align: right !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #556ee6;
}

.submit-btn-wrapper {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.dont-have-account-wrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #5a5f7d;

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #556ee6;
  }
}

.footer-text-wrapper {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #556ee6;
}

.strategy-card-divider{
  border-left: 1px solid #A6B0CF33;
}

@media (max-width: 768px) {
  img.left-side-img {
    margin: auto;
    margin-top: 24px;
    width: 360px;
  }
  .approve-box div {
    border-right: 0px solid #e3e6ef;
    padding: 0 32px;
  }
}

@media (max-width: 767px) {
  .strategy-card-divider {
    border: none;
  }
}

@media (max-width: 1024px) {
  .registration-form {
    padding: 24px !important;
    height: 100vh !important;
  }
  img.left-side-img {
    margin: auto;
    margin-top: 42px;
    width: 370px;
  }
  .approve-box {
    padding: 0px !important;
  }
  .approve-box div:last-child {
    border: none;
    padding: 0px;
  }
}
@media (max-width: 499px) {
  .registration-form {
    padding: 24px !important;
  }
  img.left-side-img {
    margin: auto;
    width: 100%;
    margin-top: 24px !important;
    display: flex;
    margin-bottom: 24px !important;
  }
  .border-shadow-outline h3,
  span {
    font-size: 15px !important;
    flex-wrap: wrap;
    align-self: center;
    margin-bottom: 0px;
  }
}

.registration-form {
  padding: 32px 136px;
  background-image: url(../../images/circles-Background.svg);
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin: 0px;

  .left-side-overlay {
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      display: inline-block !important;
      color: #333b4e;
    }

    .create-account-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: #333b4e;
    }
  }
}

.stats-info-wrapper {
  margin-top: 24px;
}

img.left-side-img {
  margin: auto;
  margin-top: 42px;
}

.font-size-12 {
  font-size: 12px;
}

.seller-info {
  display: flex;
  text-align: center;

  .text-success {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #34c38f;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #333b4e;
  }
}

.join-us-text {
  margin-top: 24px !important;
  margin-bottom: 0px;
  font-size: 20px !important;
  width: 100%;
  text-align: center;
}
span.input-group-text.colorpicker-input-addon.rounded-right {
  position: relative;
  left: -6px;
  border-top-left-radius: none !important;
}
.login-page-bg {
  clip-path: circle(50% at 36% 6%);
  background: #dacff1;
}
.approved-logos-wrapper div {
  padding: 0px;
  margin-left: 0px !important;
}
.card-over-wrapper {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px;
  color: #333b4e !important;

  .circle-text {
    font-weight: 400;
  }
}
.approved-logos-wrapper {
  margin-top: 88px;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #75757599;
    margin-bottom: 12px;
  }
}
.sortable-helper > td {
  display: table-cell;
  vertical-align: inherit;
  width: inherit;
  background-color: $body-bg;
}

.inner-caption {
  color: #a6b0cf;
}

.text-deco-line-through {
  text-decoration: line-through;
}

.agency-clients-dropdown .css-2b097c-container {
  width: 85%;
}

.z-index-2 {
  z-index: 2 !important;
}

.inventory-dropdown {
  height: 54px;
}

.outbound-filter-section{
  .select2-selection__control {
    .select2-selection__value-container {
      min-width: 80px !important;
    }
  };

}
.inputs-group-inbounds{
  .input.search-input {
    min-width: 20px !important;
    width: 20px !important;
  }
}

.border-shadow-outline {
  padding: 2rem;
  border: 1px solid #e3e6ef;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.border-outline {
  margin-top: 12px;
  padding: 20px 30px 20px 20px;
  background: #ffffff;
  border: 2px solid #e3e6ef;
  border-radius: 4px;
}

.outline-radio {
  border-color: #556ee6;
}

@media (max-width: 767px) {
  img.left-side-img {
    margin: auto;
    margin-top: 42px;
    display: flex;
  }
}
.header-banner {
  background-image: url("../../svg//banner.svg");
  background-repeat: no-repeat;
  min-height: 120px;
  background-size: cover;
  background-position: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    font-size: 20px;
  }
  .price {
    color: #fbc02d;
  }
}
.color-warning{
 color: #fbc02d;
}
.listing-banner-para {
  max-width: 488px;
}
// welcome screen
.dark .welcome-modal {
  i {
    font-size: 40px;
    color: #fff;
    margin-right: 24px;
    cursor: pointer;
  }
  .welcome-banner {
    background-color: #46517c;
    border-radius: 10px;
    h1 {
      font-size: 20px;
    }
    p {
      color: #c0c9e1;
      font-size: 13px;
      max-width: 330px;
    }
    img {
      width: 154px;
      height: 105px;
    }
  }
  .gap-4 {
    gap: 24px;
  }
  .selected-store {
    border: 1px solid rgba(147, 156, 185, 0.2);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    max-width: 209px;
    min-width: 234px;
    align-items: center;
    justify-content: center;
    padding: 27px 0;
    cursor: pointer;
    min-height: 140px;
  }
}

.light {
  .welcome-modal {
    i {
      font-size: 40px;
      color: #969aa3;
      margin-right: 24px;
      cursor: pointer;
    }
    .welcome-banner {
      background-color: #d5dcf8;
      border-radius: 10px;
      h1 {
        font-size: 20px;
      }
      p {
        color: #62697a;
        font-size: 13px;
        max-width: 330px;
      }
      img {
        width: 154px;
        height: 105px;
      }
    }
    .gap-4 {
      gap: 24px;
    }
    .selected-store {
      border: 1px solid black;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      max-width: 209px;
      min-width: 234px;
      align-items: center;
      justify-content: center;
      padding: 27px 0;
      cursor: pointer;
      min-height: 140px;
    }
  }
}

.alert-custom-badge-m{
  margin: 0px !important;
}

.subscription-border {
  border: 1px solid $subscription-border;
  border-radius: 9px;
  background-color: transparent;
  position: relative;
}
.ribbon {
  width: 138px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  text-align: center;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid rgba(52, 195, 143, 0.5);
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 6px 0;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.ribbon-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.light {
  .ribbon span {
    background-color: rgba(52, 195, 143, 1);
  }
}
.dark {
  .ribbon span {
    background-color: rgba(52, 195, 143, 0.5);
  }
}

.transparent-card{
  background-color: transparent;
  border: 1px solid #32394E ;
}
.primary-box{
  border-radius: 30px;
  border: 0.84px solid #556EE6;
  background: #556EE6;
  width:30px;
  height:30px;
      display: flex;
    align-items: center;
    justify-content: center;
    i{
      color:#fff;
      font-size: 16px;;
    }

}
.custom-progress .progress-bar {
  background-color: red;
}
.w-150{
  width:150px
}
.gap-16{
  gap:16px;
}
.divider-right{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    height: 24px;
    width: 1px;
    background-color: #424B61;
    top: 38%;
    right: -7px;
  }
}
.coming-soon{
  background-color: #556EE6;
  width:81px;
  height: 24px;
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  cursor: pointer;
}
.table-select {
  min-height: 260px;
}
.blur-effect {
  filter: blur(3px);
}
.date-picker {
 .react-datepicker-popper {
    z-index: 99;
  }
}

.no-spinners{
  input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
}

@media (max-width: 650px) {
  .strategy-ellipse-dots {
    display: none;
  }
}

.subscription-cards-width {
  margin-left: 3rem;
  margin-right: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 900px) {
  .subscription-cards-width {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}


.EZDrawer__overlay{
  z-index: 1002 !important;
}

.filters-width {
  width: 558px !important;
}

@media (max-width: 767px) {
  .filters-width {
    width: 100vw !important;
  }
}

.text-orange {
  color: #FD7923;
}

.text-sky-blue {
  color: #1892F8;
}

.text-parrot {
  color: #25C35C;
}

.text-purple {
  color: #9D60FB;
}
.responsive-div {
  width: 160px;

  @media (max-width: 768px) {
    width: 100%;
  }
}
