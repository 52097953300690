//
// custom-filters.scss
//

.custom-filter {
  background-color: $card-bg;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  transition: all 200ms ease-out;
  width: $customfilter-width;
  z-index: 9999;
  float: right !important;
  bottom: 0;
  position: fixed;
  top: 8.8%;
  right: 0;

  .custom-filter-toggle {
    background-color: lighten($dark, 7%);
    height: 24px;
    width: 24px;
    line-height: 24px;
    color: $gray-200;
    text-align: center;
    border-radius: 50%;

    &:hover {
      background-color: lighten($dark, 10%);
    }
  }
}

.custom-filter-enabled {
  .custom-filter {
    right: 0;
  }
}

@include media-breakpoint-down(sm) {
  .custom-filter {
    overflow: auto;
    .slimscroll-menu {
      height: auto !important;
    }
  }
}

.height-calc {
  overflow: auto;
  height: calc(100vh - 260px);
}
