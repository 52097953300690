.flex-center {
  display: flex;
  align-items: center;
}

.firstPromoter-add-link {
  background-color: $red;
  border-radius: 6px;
  cursor: pointer;
  border: 0.5px solid $sidebar-first-promoter-bg-color;
  &:hover {
    border: 0.5px solid $blue;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .up-div {
    display: flex;
    background-color: $sidebar-first-promoter-bg-color;
    border-radius: 6px;
    justify-content: flex-start;
    grid-column-gap: 6px;
    align-items: center;
    padding: 10px;
  }
  .heading-div {
    h6 {
      margin-bottom: 0;
    }
  }
  .description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    p {
      margin-left: 3px;
    }
  }
}
.sidebar-enable {
  ul {
    li {
      .firstPromoter-add-link {
        background-color: transparent;
        border: none;
        &:hover {
          border: none;
        }
        .up-div {
          background-color: transparent;
          padding: 6px;
          .heading-div {
            display: none;
          }
        }
        .description {
          display: none;
        }
      }
    }
  }
}
