//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-centered.select > tbody > tr:last-child {
  height: 130px;
}

//Table for Select
.table-for-select {
  td,
  th {
    vertical-align: top !important;
  }
}

.table-for-select > tbody > tr:last-child {
  height: 160px;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.inner-table {
  th {
    font-weight: $font-weight-lighter;
  }
}
