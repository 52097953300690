.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  background-color: $stripe-bg-color;
  border: 1px solid $stripe-border-color;
  border-radius: 4px;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
