// filters.scss

.filter-tag {
  border: 1px solid $blue;
  background-color: rgba(85, 110, 230, 0.24);
  border-radius: 50px;
  white-space: nowrap;
}

.filter-remove {
  background-color: $red;
  border-radius: 50px;
  right: 0;
  top: -5px;
  cursor: pointer;
  color: #fff;
}

.filter-text-clr {
  color: $filter-text-color;
}
