//
// registration_docs.scss
//

.flex-rows {
  display: flex;
  width: 100%;
  .table-of-content {
    width: 30%;
    display: flex;
    justify-content: flex-start;
  }
  .detail {
    width: 70%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .table-of-content {
      width: 100%;
      justify-content: center;
    }
    .detail {
      width: 100%;
    }
  }
}
